#box{
    margin: 0;
    padding: 0;
    background-color: #0c1320;
    height: 100vh;
}

#box .container #box-row #box-column #box-box {
    margin-top: 80px;
    max-width: 600px;
    height: 400px;
    border: 2px solid #9C9C9C;
    background-color: #EAEAEA;
}
#box .container #box-row #box-column #box-box #box-form {
    padding: 10px;
}
#box .container #box-row #box-column #box-box #box-form #box-link {
    margin-top: -70px;
}